@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.title {
    font-family: "Playfair Display", serif;
}

.classicFont {
    font-family: "Barlow", sans-serif;
}

.contactBorder{
    border: solid #A25341 3px;
}

.Menu-Phone {
    div {
        span {
            &::before {
                content: '';
                width: 100%;
                height: 100%;
                background-color: #673e35;
                position: absolute;
                transition: .5s;
            }
            &::after {
                content: '';
                width: 100%;
                height: 100%;
                background-color: #673e35;
                position: absolute;
                transition: .5s;
            }
            &.hideMenu::before {
                transform: translateY(-7px);
            }
            &.hideMenu::after {
                transform: translateY(7px);
            }
            &.showedMenu::before {
                transform: rotate(45deg);
            }
            &.showedMenu::after {
                transform: rotate(-45deg);
            }
        }
    }
}

.MenuPhoneOption {
    &.active-enter {
        opacity: 0;
        transform: translateY(-100vh);
    }
    
    &.active-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.5s, transform 0.5s;
    }
    
    &.active-exit {
        opacity: 1;
    }
    
    &.active-exit-active {
        opacity: 0;
        transition: opacity 0.5s;
    }
}

.Accordion-answer{
    height: 0px;
    overflow: hidden;
    
    &.active-enter {
        height: 0px;
    }
    
    &.active-enter-active {
        height: 100px;
        transition: .5s;
    }

    &.active-enter-done{
        height: 100px;
    }
    
    &.active-exit {
        height: 100px;
        transition: .5s;
    }
    
    &.active-exit-active {
        height: 0px;
        transition: .5s;
    }
}

.NavDesktop{
    transition: .2s;

    &.active{
        color: white;
        background-color: #535353d6;
        padding-bottom: 13px;
    }

    .selected{
        text-decoration: underline;
    }
}

.Prices{
    .swiper{
        width: 320px;
        height: 420px;
        padding: 15px;
        margin: 0;

        .swiper-slide
        {
            height: 90%;
            width: 90%;
            overflow: hidden;
            margin-bottom: 20px;
            border-radius: 20px;
        }
        .swiper-pagination{
            height: 10%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            
            .swiper-pagination-bullet{
                width: 10px;
                height: 10px;
                margin: 0px 10px;
                transition: .2s;
                
                &.swiper-pagination-bullet-active{
                    width: 15px;
                    height: 15px;
                    background-color: #673e35;
                }
            }
        
        }

        .swiper-button-prev{
            left: 20px;
            color: white;
            background-color: #0000007a;
            padding: 25px;
            border-radius: 50%;
            padding-right: 27px;
            
            &::after{
                font-size: 30px;
            }
        
        }

        .swiper-button-next{
            right: 20px;
            color: white;
            background-color: #0000007a;
            padding: 25px;
            padding-left: 27px;
            border-radius: 50%;
            
            &::after{
                font-size: 30px;
            }
        }
    }
}

@media screen and (orientation: landscape) {
    .HomePageHeader {
        flex-direction: row;
        
        .TextContainer {
            justify-content: center;
            height: 100%;
            width: 60%;
            font-size: 30px;
            column-gap: 32px;

            &:first-child {
                font-size: 7vmin;
            }

            p {
                font-size: 20px;
            }
        }

        .ImgContainer {
            position: static;
            width: 40%;

            img {
                object-position: left;
            }
        }
    }

    .Colors {
        display: flex;
        flex-direction: row-reverse;
    }
}

@media screen and (min-width: 530px) and (min-height:500px) {
    .HomePageHeader {
        .TextContainer {
            p:first-child{
                font-size: 7vmin;
            }

            p:last-child{
                font-size: 20px;
            }
        }       
    }
}

@media screen and (min-width: 1500px) and (min-height:500px) {
    .HomePageHeader {
        .TextContainer{
            p:last-child {
                font-size: 3vmin;
            }
        }
    }       
}

@media screen and (min-width:800px) and (orientation: landscape){
    .HomePageDetails{
        padding-top: 200px;
    }

    .Wether{
        padding-top: 150px;
    }

    .Colors{
        padding-top: 150px;
    }

    .Images{
        margin-top: 150px;
    }

    .UseCases{
        margin-top: 200px;
    }
}

@media screen and (min-width:420px){
    .Contact{
        div:last-child{
            max-width: 400px;
            
            p{
                font-size: 25px;
                min-width: 236px;
            }
        }
    }

    .UseCases{
        div{
            p{
                font-size: 25px;
            }
        }

        
    }
}

@media screen and (min-width:700px){
    .Wether{
       div{
        .title{
            p{
                font-size: 50px;
            }
        }
       }
    }

    .Colors{
        .title{
            font-size: 70px;
         }

         .classicFont{
            font-size: 30px;
         }
    }

    .Prices{

        .swiper{
            width: 400px;
            height: 520px;
        }

        .Text{
            max-width: 1000px;

            p:first-child{
                font-size: 35px;
            }
            margin-bottom: 20px;
        }

        
            ol{
                font-size: 20px;
            }
        
    }

    @media screen and (min-width:1400px){
        .Prices{
            flex-direction: row;

            .swiper{
                width: 400px;
                height: 520px;
            }
    
            .Text{
                max-width: 1200px;
    
                p:first-child{
                    font-size: 35px;
                }
                margin-bottom: 20px;
            }
            
            .Swiper-container{
                P{
                    font-size: 20px;
                }
            }
        
        
        }

        .Info-Container{
            column-gap: 170px;

            img{
                max-width: 500px;
            }
        }

        .Wether{
            column-gap: 200px;

            div{
                max-width: 800px;
                .title{
                    
                    p {
                        font-size: 60px;
                    }
                }

                div{
                    .classicFont{
                        font-size: 20px;
                    }
                }
            }
            
            img{
                max-width: 350px;
            }
        
        }
    }

    .Info{
        font-size: 20px;
        margin-bottom: 20px;
    }

    .UseCases{
        div{
            p{
                font-size: 30px;
            }
        }
    }
}

@media screen and (min-width:1000px){
    .Contact{
        justify-content: center;
        padding-top: 0;
        flex-direction: row;
        column-gap: 30px;

        div:first-child{
           
                p{
                    font-size: 23px;
                }
            }
        }

    .Realizations{
        
            .classicFont{
                justify-content: end;

                p{
                    font-size: 25px;
                }    
                
            }
        
    }
    }

    @media screen and (min-width:1200px){
        .Contact{
            justify-content: center;
            padding-top: 0;
            flex-direction: row;
            column-gap: 50px;
    
            div:first-child{
                max-width: 700px;
                
                p:first-child{
                        font-size: 30px;
                    }
                }
        }
        .Realizations{
            div:first-child{
                .title{
                    font-size: 72px;
                }
            }
        }

        .Info-Container{
            margin-top: 50px;
            flex-direction: row;
            justify-content: center;
        }

        .UseCases{
            flex-direction: row-reverse;
            column-gap: 70px;
            
            .classicFont{
                row-gap: 50px;
            }
        
        }
        
    }

    

    @media screen and (min-width:1500px){
        .Prices{
            .Text{
                p:first-child{
                    font-size: 41px;
                }
                margin-bottom: 20px;
            }
        }

        .UseCases{
            column-gap: 330px;
            
            .classicFont{
                row-gap: 80px;
            }
        
        }
    }

    @media screen and (min-width:1700px){
        .Prices{
            .Text{
                p:first-child{
                    font-size: 45px;
                }
                margin-bottom: 20px;
            }
        }
    }

    @media screen and (min-width:2000px){
        .Prices{
            flex-direction: row;
            column-gap: 100px;

            .swiper{
                width: 400px;
                height: 520px;
            }
    
            .Text{
                max-width: 1200px;
    
                p:first-child{
                    font-size: 50px;
                }
                margin-bottom: 20px;
            }
        }
    }

    



